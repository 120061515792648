:root {
  --dim-pixel: 1px;
  --dim-xxxxs: 2px;
  --dim-xxxs: 4px;
  --dim-xxs: 6px;
  --dim-7: 7px;
  --dim-xs: 8px;
  --dim-sssm: 10px;
  --dim-ssm: 12px;
  --dim-14: 14px;
  --dim-sm: 16px;
  --dim-mdss: 18px;
  --dim-mds: 20px;
  --dim-md: 24px;
  --dim-lg: 32px;
  --dim-xl: 40px;
  --dim-xxl: 48px;
  --dim-xxxl: 56px;
  --dim-xxxxl: 64px;
  --dim-col-xxxxs: 24px;
  --dim-col-xxxs: 48px;
  --dim-col-xxs: 72px;
  --dim-col-xs: 96px;
  --dim-col-ssm: 120px;
  --dim-col-sm: 144px;
  --dim-col-md: 168px;
  --dim-col-lg: 192px;
  --dim-col-xl: 216px;
  --dim-col-xxl: 240px;
  --dim-col-xxxl: 264px;
  --dim-col-xxxxl: 288px;

  /* Doc editor */
  --doc-editor-layout-gap: var(--dim-xs);
}
