.sidenav-container {
  mat-sidenav {
    border-radius: var(--border-radius) !important;
    padding: var(--dim-ssm) !important;
    background: var(--surface-1) !important;
    transition-duration: var(--animation-duration-default);
    transition-timing-function: var(--animation-timing-default);
    margin-bottom: var(--dim-sm);
    box-shadow: none !important;
  }

  mat-sidenav-content {
    width: 100% !important;
    transition-duration: var(--animation-duration-default);
    transition-timing-function: var(--animation-timing-default);
  }

  .mat-drawer-side {
    border: none !important;
  }

  mat-expansion-panel {
    margin: 0 !important;
  }

  mat-expansion-panel-header {
    padding: 0 !important;
    margin-bottom: var(--dim-xxs) !important;
  }

  .mat-expansion-panel {
    background: var(--surface-1);
  }

  .mat-expansion-panel-header.mat-expanded {
    height: var(--dim-col-xxxs);
  }

  .mat-expansion-panel-header-title {
    margin-right: 0 !important;
    display: flex;
    justify-self: center;
  }

  div.mat-mdc-menu-content {
    margin-right: var(--dim-ssm);
    margin-left: var(--dim-ssm);
  }

  mat-nav-list div div {
    padding: 0 !important;
  }

  mat-list-item.button > span > span {
    display: grid;
    border-radius: var(--border-radius-sidenav-item);
    grid-template-columns: var(--dim-col-xxxs) var(--dim-col-lg);
    align-items: center !important;
  }

  div.mat-expansion-panel-content > div > mat-list-item > span > span {
    display: flex;
    border-radius: var(--border-radius-sidenav-item);
    align-items: center !important;
  }

  .mat-content {
    display: grid !important;
    flex: none !important;
    grid-template-columns: var(--dim-col-xxxs) var(--dim-col-md) !important;
  }
}


:is(aodocs-document-sidenav-panels, aodocs-document-sidenav-attachments) {
  &:not(:last-child) {
    padding-bottom:  var(--doc-editor-layout-gap);
  }
}

aodocs-document-sidenav-panels {
  box-sizing: border-box;

  .sidenav-container {
    mat-sidenav {
      height: 100% !important;
    }

    .mat-drawer-inner-container {
      overflow: hidden;
    }

    mat-nav-list {
      display: grid !important;
      grid-template-rows: auto auto 1fr;
      height: 100%;
    }

    mat-accordion {
      overflow: auto;
    }

    mat-expansion-panel {
      height: 100%;
    }

    .mat-expansion-panel-content[role='region'] {
      overflow: auto;
      height: calc(100% - 40px);
    }
  }
}

aodocs-document-sidenav-attachments {
  box-sizing: border-box;

  .sidenav-container {
    mat-sidenav {
      height: 100% !important;
    }

    mat-list-item {
      .label {
        padding-right: var(--dim-mds);
      }
    }
  }
}

.document-sidenav {
  $document-sidenav-color: var(--neutral-10);
  $document-sidenav-font: var(--title-menu);
  $document-sidenav-font-size: var(--dim-14);
  $document-sidenav-item-background: var(--primary-90);
  $document-sidenav-item-height: 40px;
  $icon-size: var(--dim-md);

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  mat-sidenav-container {
    display: block !important;
    padding: 0 !important;
    overflow: initial;

    .mat-ripple-element {
      display: none;
    }

    .mdc-list-item:focus::before {
      opacity: 0 !important;
    }

    .icons {
      display: flex;
      justify-self: center;
      color: $document-sidenav-color !important;
      fill: $document-sidenav-color !important;
      font-size: $icon-size !important;
      height: $icon-size !important;
      width: $icon-size !important;
    }

    mat-nav-list {
      padding: 0 !important;
    }

    mat-sidenav {
      height: fit-content;
      margin: 0;
      padding: var(--dim-sssm) !important;

      :is(mat-list-item, mat-expansion-panel-header) {
        height: $document-sidenav-item-height !important;
        margin: 0 !important;

        .label {
          font: $document-sidenav-font;
          font-size: $document-sidenav-font-size;
          color: $document-sidenav-color !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:is(.highlighted, .selected) {
          background-color: $document-sidenav-item-background !important;
        }

        &:hover {
          background-color: $document-sidenav-item-background !important;
        }

        &::before {
          display: none;
        }

        &:focus:not(.highlighted, .selected, :active) {
          background-color: transparent !important;
        }
      }

      .mat-expansion-panel-body {
        max-height: 400px;
      }

      mat-list-item {
        height: $document-sidenav-item-height !important;
        margin-bottom: 0 !important;
      }

      &.mat-drawer {
        position: initial;
      }

      mat-expansion-panel:has(mat-list-item.highlighted) mat-expansion-panel-header.properties:not(.mat-expanded) {
        background-color: $document-sidenav-item-background !important;
      }
    }

    mat-sidenav.expanded {
      mat-expansion-panel-header {
        .mat-content {
          padding-left: var(--dim-xxxs);
        }

        .mat-expansion-indicator {
          translate: -10px 1px;
          height: 30px;
          min-width: 40px;
          display: grid;
          place-items: center;

          &::after {
            $icon-size: 7px;

            border-left: $icon-size solid $document-sidenav-color;
            border-bottom: $icon-size solid transparent;
            border-right: $icon-size solid transparent;
            border-top: none;
            translate: 2px 4px;
            padding: 0;
          }
        }
      }

      .mat-expansion-panel-body {
        overflow: auto;
        padding: 0 !important;
      }

      .sub-menu-padding {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: var(--dim-xxl);
        padding-right: var(--dim-mds);
      }

      .mdc-list-item__content {
        padding-left: var(--dim-xxs);
      }
    }

    mat-sidenav:not(.expanded) {
      width: 60px !important;

      .mat-drawer-inner-container::-webkit-scrollbar {
        display: none;
      }

      mat-list-item {
        width: 40px;
        margin: auto !important;

        .mdc-list-item__content {
          padding: 0;

          & > span {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    mat-sidenav-content {
      display: none;
    }
  }
}


.sidenav-panels-overlay-pane {
  $document-sidenav-item-height: 40px;
  $document-sidenav-font-size: var(--dim-14);

  mat-menu-item {
    min-height: $document-sidenav-item-height;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 600px;
  }

  .mat-mdc-menu-item {
    padding: var(--dim-sm);
  }

  .mdc-list-item__primary-text {
    font-size: $document-sidenav-font-size !important;
    line-height: 1.2 !important;
  }
}
