@use '../tokens/components/header';

$header-height: var(--dim-xxxxl);
$header-padding: 0 var(--dim-md);

aodocs-header {
  &.end-user {
    header {
      mat-toolbar {
        background: header.$header-primary-background-color;
        font: var(--font-document-title-header);
        letter-spacing: var(--letter-spacing-025);
        color: var(--color-white);
        height: 48px;
        transition-duration: var(--animation-duration-default);
        transition-timing-function: var(--animation-timing-default);

        mat-icon {
          fill: var(--color-white);
        }

        &.collapsed {
          height: 0;
          transition-duration: var(--animation-duration-default);
          transition-timing-function: var(--animation-timing-default);

          p,
          mat-icon,
          .mat-mdc-form-field-flex {
            display: none;
          }
        }
      }
    }
  }

  &.edit {
    header {
      mat-toolbar {
        background: var(--color-white);
        color: var(--color-gray);

        .left mat-icon {
          fill: var(--color-gray);
        }
      }
    }
  }
}

aodocs-header.domain-admin {
  header {
    mat-toolbar {
      height: $header-height;
      padding: $header-padding;
      background: var(--surface-5);
    }
  }
}
