::-webkit-scrollbar {
  width: var(--dim-mds);
  box-sizing: border-box;
}

::-webkit-scrollbar-thumb {
  border-radius: var(--dim-mds);
  box-shadow: inset 0 0 var(--dim-mds) var(--dim-mds) var(--neutral-70);
  border: solid var(--dim-7) transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
