/* stylelint-disable scss/no-global-function-names */

@function create-palette($color) {
  $palette: ();
  $color: adjust-color($color, $lightness: -100%);

  @for $i from 0 through 100 {
    $new-color: adjust-color($color, $lightness: $i * 1%);
    $palette: append($palette, $new-color);
  }

  @return $palette;
}
