.ao {
  &Row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1em;
    row-gap: 1em;

    // content
    &--left {
      justify-content: left;
    }
    &--btw {
      justify-content: space-between;
    }
    &--right {
      justify-content: right;
    }

    // align
    &--start {
      align-items: flex-start;
    }
    &--center {
      align-items: center;
    }
    &--end {
      align-items: flex-end;
    }

    &--fill:only-child {
      flex: 1 0 0;
    }

    &--noGap {
      column-gap: 0;
      row-gap: 0;
    }

    &--mGap {
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  &Fill {
    flex: 1 0 0;
  }

  &Small {
    flex: 0.3 0 0;
  }

  &Hide {
    display: none;
  }
}


@media screen and (max-width: 1050px) {
  .ao {
    &Show {
      &-md {
        display: block;
      }
    }

    &Hide {
      &-md {
        display: none !important;
      }
    }
  }
}

@media screen and (min-width: 1050px) {
  .ao {
    &Show {
      &-md {
        display: none !important;
      }
    }

  }
}
