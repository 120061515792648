mat-dialog-container > div > div.mat-mdc-dialog-surface {
  background: var(--primary-90);
}

.custom-dialog {
  .mdc-dialog__title::before {
    height: 0;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: var(--dim-md);
    overflow: hidden;
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-actions,
    .mdc-dialog__content,
    .mdc-dialog__title {
      padding: 0;
    }
  }
}

.highlighted-dialog, .csv-rules-import-statistics {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
    --mdc-dialog-supporting-text-color: var(--primary-0);

    .mdc-dialog__title {
      color: var(--primary-100);
      background-color: var(--primary-40);
      padding: 0 var(--dim-md) var(--dim-xs);
    }

    button.close-button {
      position: absolute;
      top: var(--dim-ssm);
      right: var(--dim-ssm);
      box-shadow: none;
    }

    .mat-mdc-dialog-content{
      overflow-x: hidden;
    }
  }
}
