/* stylelint-disable scss/no-global-function-names */
@use 'sass:color';
@import '../functions/functions';

$palettes: (
  primary: create-palette(#0062a0),
  secondary: create-palette(#005bbe),
  tertiary: create-palette(#6a5779),
  error: create-palette(#ba1a1a),
  neutral: create-palette(#5d5e61),
  neutral-variant: create-palette(#5a5f66),
);

:root {
  --surface-base: #fcfcff;
  --surface-mask-rgb: 0 98 160;
  --secondary-mask-rgb: 0 91 190;
  --green-save: #87c639;
  --green-save-hover: #6fba11;
  --green-save-focus: #58a716;
  --green-save-disabled: #b7dd88;

  // To generate automatically palettes based on a tint
  // @each $type, $palette in $palettes {
  //   @for $i from 1 through length($palette) {
  //     $tonality: $i - 1;

  //     @if $tonality % 10 == 0 or $tonality == 95 or $tonality == 99 {
  //       $color: nth($palette, $i);

  //       --#{$type}-#{$tonality}: #{$color};
  //     }
  //   }
  // }

  /* blue */
  --ao-primary-trans: rgb(62 130 247 / 20%);
  --primary-0: #000;
  --primary-10: #141641;
  --primary-20: #202364;
  --primary-30: #3d6fe2;
  --primary-40: #3e82f7;
  --primary-50: #3c90ff;
  --primary-60: #4da1ff;
  --primary-70: #69b2ff;
  --primary-80: #94c8ff;
  --primary-90: #beddff;
  --primary-95: #e4f2ff;
  --primary-99: #f7fbff;
  --primary-100: #fff;
  --secondary-0: #000;
  --secondary-10: #001a40;
  --secondary-20: #002f67;
  --secondary-30: #004492;
  --secondary-40: #005bbe;
  --secondary-50: #0973ec;
  --secondary-60: #488fff;
  --secondary-70: #7eabff;
  --secondary-80: #acc7ff;
  --secondary-90: #d7e2ff;
  --secondary-95: #edf0ff;
  --secondary-99: #fefbff;
  --secondary-100: #fff;
  --neutral-0: #000;
  --neutral-10: #1a1c1e;
  --neutral-20: #2f3033;
  --neutral-30: #45474a;
  --neutral-40: #5d5e61;
  --neutral-50: #76777a;
  --neutral-60: #909094;
  --neutral-70: #ababae;
  --neutral-80: #c6c6ca;
  --neutral-90: #e2e2e6;
  --neutral-95: #f1f0f4;
  --neutral-97: #f9f9fc;
  --neutral-99: #fcfcff;
  --neutral-100: #fff;
  --neutral-variant-0: #000;
  --neutral-variant-10: #171c22;
  --neutral-variant-20: #2c3137;
  --neutral-variant-30: #42474e;
  --neutral-variant-40: #5a5f66;
  --neutral-variant-50: #73777f;
  --neutral-variant-60: #8c9199;
  --neutral-variant-70: #a7abb3;
  --neutral-variant-80: #c2c7cf;
  --neutral-variant-90: #dee3eb;
  --neutral-variant-95: #edf1f9;
  --neutral-variant-99: #fcfcff;
  --neutral-variant-100: #fff;

  /* grey transparent */
  --neutral-trans-3: rgb(0 0 0 / 3%);
  --neutral-trans-4: rgb(0 0 0 / 4%);
  --neutral-trans-5: rgb(0 0 0 / 5%);
  --neutral-trans-12: rgb(0 0 0 / 12%);
  --neutral-trans-24: rgb(0 0 0 / 24%);
  --neutral-trans-34: rgb(0 0 0 / 34%);
  --neutral-trans-42: rgb(0 0 0 / 42%);
  --neutral-trans-54: rgb(0 0 0 / 54%);
  --neutral-trans-65: rgb(0 0 0 / 65%);
  --neutral-trans-87: rgb(0 0 0 / 87%);
}
